import "./Results.css";
import Check from "../Check";
import { Card } from "../Card";
import Table from "./Table";
import React, { useState, createRef, useEffect } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { CONNECTION_MODES, CONNECTION_STATUS } from "../../utils/constants";
import { TestStatus } from "../TestStatus";

export default function Results({
  natService,
  connectionState,
  websocketTests,
  isTesting,
  testData,
  addTestDataForKey,
  advancedTestResult,
}) {
  const [iceCandidates, setIceCandidates] = useState(null);
  const [protocolsFailed, setProtocolsFailed] = useState([]);
  const [protocolsConnected, setProtocolsConnected] = useState([]);
  const [resultBody, setResultBody] = useState("");
  const [percentageSuccessful, setPercentageSuccessful] = useState(0);

  const ListOfServices = () => {
    return (
      <section>
        <h2>Server</h2>

        <ul className="servicesList">
          <li>
            <TestStatus
              loading={!connectionState[CONNECTION_MODES.STUN].result}
              pass={protocolsConnected.includes(CONNECTION_MODES.STUN)}
            />
            STUN connectivity
          </li>
          <li>
            <TestStatus
              loading={!connectionState[CONNECTION_MODES.TURN_UDP].result}
              pass={protocolsConnected.includes(CONNECTION_MODES.TURN_UDP)}
            />
            TURN UDP connectivity
          </li>
          <li>
            <TestStatus
              loading={!connectionState[CONNECTION_MODES.TURN_TCP].result}
              pass={protocolsConnected.includes(CONNECTION_MODES.TURN_TCP)}
            />
            TURN TCP connectivity
          </li>
          <li>
            {" "}
            <TestStatus
              loading={!connectionState[CONNECTION_MODES.TURN_TLS].result}
              pass={protocolsConnected.includes(CONNECTION_MODES.TURN_TLS)}
            />
            TURN TLS connectivity
          </li>
        </ul>
      </section>
    );
  };

  const canConnect = () => {
    return (
      <>
        <p>
          <b>{percentageSuccessful}% successful</b>. Your network can
          communicate over all protocols.{" "}
          <b>This means you can make video calls.</b>
        </p>
      </>
    );
  };

  const cannotConnect = () => {
    return (
      <>
        <p>
          <b>{percentageSuccessful}% successful</b>. Your network cannot
          establish communication over any of the available protocols.{" "}
          <b>This means you cannot make video calls.</b>
        </p>
      </>
    );
  };

  const setTestResults = () => {
    if (isTesting) {
      return;
    }
    const connected = Object.keys(connectionState).filter(
      (key) => connectionState[key].result === "connected"
    );
    const failed = Object.keys(connectionState).filter(
      (key) => connectionState[key].result === "failed"
    );

    setProtocolsConnected(connected);
    setProtocolsFailed(failed);

    const total = Object.keys(connectionState).length;
    const successRate = (connected?.length / total) * 100;
    setIceCandidates(connectionState[CONNECTION_MODES.ALL]?.iceCandidates);
    setPercentageSuccessful(successRate);
  };

  useEffect(() => {
    setTestResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState]);

  useEffect(() => {
    if (!connectionState[CONNECTION_MODES.RELAY_ONLY].result) {
      return;
    }
    if (
      connectionState[CONNECTION_MODES.RELAY_ONLY].result ===
      CONNECTION_STATUS.CONNECTED
    ) {
      // What we'd like to see in most of the cases.
      setResultBody(canConnect);
    }

    // If you literally cannot connect to anything. Should be rare
    if (
      connectionState[CONNECTION_MODES.RELAY_ONLY].result ===
      CONNECTION_STATUS.FAILED
    ) {
      setResultBody(cannotConnect);
      // no ice candidates, so keep setIceCandidates null
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolsFailed, protocolsConnected]);

  const ref = createRef();
  const [, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const fileName = `daily-${natService}-${new Date().toISOString()}`;
  const download = (
    screenshot,
    { name = fileName, extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = screenshot;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  const refreshPage = () => window.location.reload();

  const WebsocketResults = () => {
    const testRows = Object.entries(websocketTests).map(
      ([region, resultsObj]) => {
        return (
          <li key={region}>
            <TestStatus
              loading={resultsObj.result === "loading"}
              pass={resultsObj.result === "passed"}
            />

            {region}
            {resultsObj.resultMsg && resultsObj.result === "failed" && (
              <>
                <br />
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontStyle: "italic",
                    lineHeight: "0.6rem",
                  }}
                >
                  {resultsObj.resultMsg}
                </span>
              </>
            )}
          </li>
        );
      }
    );
    return (
      <section>
        <h2>Websocket Regions</h2>
        <ul className="servicesList">{testRows}</ul>
      </section>
    );
  };

  return (
    <>
      <div className="buttonsWrapper">
        <button onClick={downloadScreenshot}>Download result as .jpg</button>
        <button onClick={refreshPage} className="secondary">
          Re-take test
        </button>
      </div>
      <div ref={ref} className="screenshotArea">
        <Card isBigCard>
          <h1>Test results</h1>
          <p>
            These tests can take up to 15 seconds to complete. Test ID:{" "}
            <code>{testData.id}</code>
          </p>
          <div className="grid">
            <ListOfServices />
            <Check testData={testData} addTestDataForKey={addTestDataForKey} />
            <WebsocketResults />
          </div>
          {advancedTestResult && (
            <>
              <p>
                <strong>Advanced Network Test: </strong>
              </p>
              <ul>
                {advancedTestResult.results &&
                  advancedTestResult.results.map((r, i) => (
                    <li key={i}>{r}</li>
                  ))}
              </ul>
            </>
          )}
          {resultBody}
          {!isTesting && iceCandidates && iceCandidates.length ? (
            <Table iceCandidates={iceCandidates} />
          ) : (
            "" // React will render a "0" if you don't do this
          )}
        </Card>
      </div>
    </>
  );
}

const opaqueFetch = async (url, options = {}) => {
  const { timeout = 8 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => {
    console.log("aborting!");
    connected = false;
    controller.abort();
  }, timeout * 1000);

  let connected = true;
  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    console.log({ from: "opaqueFetch", response });

    return { connected, response };
  } catch (e) {
    return { connected: false, error: e };
  }
};

const trySocket = async (wss, timeout = 8) => {
  let result = await new Promise((resolve) => {
    console.log("trying socket: ", wss);
    const testSocket = new WebSocket(wss);

    let failedTimeout = setTimeout(() => {
      testSocket.close();
      resolve({
        connected: false,
        message: `Connection to ${wss} timed out after ${timeout} seconds.`,
      });
    }, timeout * 1000);
    try {
      testSocket.addEventListener("open", () => {
        clearTimeout(failedTimeout);
        testSocket.close();
        resolve({ connected: true });
      });
      testSocket.addEventListener("error", (e) => {
        clearTimeout(failedTimeout);
        console.log("omg caught a websocket error", e);
        testSocket.close();
        resolve({
          connected: false,
          message: `Websocket connection to ${wss} failed.`,
        });
      });
    } catch (e) {
      resolve({ connected: false, error: e });
    }
  });
  return result;
};

// Some magical options:
// `timeout`: if you don't want the default of 8 seconds, specify it here (in seconds)
// `mode`: will be 'no-cors' unless you set it to something different
export default async function connectTo(url, options = {}) {
  // the test should always resolve the promise with an object containing
  // at least these properties:
  // `connected`: true or false, depending on whether any kind of connection
  // was successful. false with nothing else means a timeout.
  // `error`: if connected is false, the presence of an `error` object indicates
  // the failure may have been something other than a timeout.
  // `response`: If connected is true, return the `response` object in case
  // the caller wants to do something with it. Since we default to `no-cors`,
  // it probably won't have anything meaningful
  options.timeout = options.timeout || 8;
  options.mode = options.mode || "no-cors";

  if (url.match(/^http/)) {
    console.log("starting opaquefetch");
    const result = await opaqueFetch(url, options);
    console.log("past opaquefetch, result is ", result);
    return result;
  } else if (url.match(/^ws/)) {
    const result = await trySocket(url, options.timeout);
    return result;
  } else {
    return {
      connected: false,
      error: "Unknown test type. Make sure the URL starts with ws or http",
    };
  }
}

// disabling to ship TLS check
// import Resolver from "dns-over-http-resolver";

// const dohResolver = new Resolver();

import connectTo from "./connectTo";

export const prodRegions = [
  "af-south-1",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-south-1",
  "eu-central-1",
  "eu-west-2",
  "sa-east-1",
  "us-east-1",
  "us-west-2",
  "qa-asia-south-1",
];

export const stagingRegions = [
  "qa-us-west-2",
  "qa-eu-west-2",
  "qa-ap-south-1",
  "qa-af-south-1",
];

let failedSockets = [];

export const startTest = async (region, staging) => {
  try {
    const checkUrl = staging
      ? `https://gs.staging.daily.co/rooms/check/chad-hq/${region}`
      : `https://gs.daily.co/rooms/check/test-rooms/${region}`;
    const apiResponse = await fetch(checkUrl);
    let apiJson = await apiResponse.json();
    let wss = apiJson.worker.wssUri;
    console.log(`[${region}] Testing connection to: ${wss}`);
    // for testing purposes
    //wss = wss.replace("ap-south-1", "ap-fail-south-1");
    let result = await connectTo(wss);
    if (result.connected === false) failedSockets.push(wss);
    return { region, result };
  } catch (e) {
    console.error(`[${region}] Caught error:`, e);
    // eslint-disable-next-line no-throw-literal
    return { region, result: { connected: false, error: e } };
  }
};

export const startWebsocketTests = (staging = false) => {
  const regions = staging ? stagingRegions : prodRegions;
  return regions.map((r) => {
    return startTest(r, staging);
  });
};

export const advancedNetworkTest = async () => {
  console.log("starting advanced test");
  const results = [];

  const leFetch = connectTo(
    "https://letsencrypt.org/images/letsencrypt-logo-horizontal.svg"
  );
  const renderFetch = connectTo("https://customerbot.onrender.com/healthz");
  const renderLeFetch = connectTo("https://telex.chadbailey.net/healthz");
  const [leResult, renderResult, renderLeResult] = await Promise.all([
    leFetch,
    renderFetch,
    renderLeFetch,
  ]);
  //let dashSwapUrl = failedSockets[0].replace("wss://", "https://");
  //dashSwapUrl = dashSwapUrl.replace(":443", "");
  let dashSwapUrl, dashSwapHttpUrl;
  if (failedSockets[0]) {
    dashSwapUrl = failedSockets[0].replace(".wss.daily", "-wss.daily");
    dashSwapHttpUrl = dashSwapUrl
      .replace("wss://", "https://")
      .replace(":443", "");
  }
  // for test purposes
  // dashSwapUrl = dashSwapUrl.replace("-fail", "");
  const dashFetch = connectTo(dashSwapUrl);
  const dashHttpFetch = connectTo(dashSwapHttpUrl);

  const [dashResult, dashHttpResult] = await Promise.all([
    dashFetch,
    dashHttpFetch,
  ]);
  results.push(
    `Dash-replacement result for ${dashSwapUrl}: ${dashResult.connected}`
  );
  results.push(
    `Dash-replacement HTTPS result for ${dashSwapHttpUrl}: ${dashHttpResult.connected}`
  );

  /*
  hostname                   IPv4-only?   LE Cert?
  ip-123.wss.daily.co        YES          YES
  letsencrypt.org            NO           YES
  customerbot.onrender.com   YES          NO
  telex.chadbailey.net       YES          YES
  */
  let result = ``;
  if (
    leResult.connected &&
    renderResult.connected &&
    renderLeResult.connected
  ) {
    result =
      "TLS certificate and IPv6 tests passed. You may need to check your DNS configuration.";
  } else if (
    leResult.connected &&
    !renderResult.connected &&
    !renderLeResult.connected
  ) {
    result =
      "IPv6-to-IPv4 compatibility check failed. You may be using an IPv6-only ISP with a misconfigured gateway. Please try disabling IPv6 support in your computer's network settings.";
  } else if (
    !leResult.connected &&
    renderResult.connected &&
    !renderLeResult.connected
  ) {
    const browserLink = failedSockets[0]
      .replace("wss://", "https://")
      .replace(":443", "");
    result = (
      <>
        {`TLS certificate check failed. You can see a more specific explanation by opening `}
        <a href={browserLink} target="_blank" rel="noreferrer">
          {browserLink}
        </a>
        {` in a new tab and looking at the lock icon in the address bar. If you see ERR_CERT_DATE_INVALID, your system clock may be wrong. If you see ERR_CERT_AUTHORITY_INVALID, you may be using an older operating system that doesn't support the 'ISRG Root X1' certificate. Please see `}
        <a
          href="https://letsencrypt.org/docs/certificate-compatibility"
          target="_blank"
          rel="noreferrer"
        >
          https://letsencrypt.org/docs/certificate-compatibility
        </a>{" "}
        {` for more information.`}
      </>
    );
  } else {
    result = `Unknown result. LE: ${leResult?.connected}; Render: ${renderResult?.connected}; Render Custom Domain (LE cert): ${renderLeResult?.connected}`;
  }
  results.push(result);
  console.log({ results });

  return { leResult, renderResult, renderLeResult, results };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Card } from "./components/Card";
import Results from "./components/Results";
import NetworkTester from "./utils/NetworkTester.js";
import "./App.css";
import { CONNECTION_MODES } from "./utils/constants.js";
import {
  startWebsocketTests,
  advancedNetworkTest,
  prodRegions,
  stagingRegions,
} from "./utils/WebsocketTester";
import { useDailyTest } from "./hooks/useDailyTest";

function App() {
  const [loading, isLoading] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [startedTesting, setStartedTesting] = useState(false);
  const [connectionState, setConnectionState] = useState({
    [CONNECTION_MODES.ALL]: {
      result: null,
      iceCandidates: null,
    },
    [CONNECTION_MODES.RELAY_ONLY]: {
      result: null,
      iceCandidates: null,
    },
    [CONNECTION_MODES.STUN]: {
      result: null,
      iceCandidates: null,
    },
    [CONNECTION_MODES.TURN_UDP]: {
      result: null,
      iceCandidates: null,
    },
    [CONNECTION_MODES.TURN_TCP]: {
      result: null,
      iceCandidates: null,
    },
    [CONNECTION_MODES.TURN_TLS]: {
      result: null,
      iceCandidates: null,
    },
  });
  const [iceServers, setIceServers] = useState({});
  const [natService, setNatService] = useState("twilio");
  const [websocketTests, setWebsocketTests] = useState({});
  const [runAdvancedTest, setRunAdvancedTest] = useState(false);
  const [advancedTestResult, setAdvancedTestResult] = useState();
  const [staging, setStaging] = useState(false);
  const { testData, addTestDataForKey } = useDailyTest();

  useEffect(() => {
    isLoading(true);
    const service =
      natService === "twilio"
        ? "https://prod-ks.pluot.blue/tt-150331.json"
        : "https://prod-ks.pluot.blue/x-stun";
    fetch(service)
      .then((response) => response.json())
      .then((data) => setIceServers(data))
      .then(() => isLoading(false));
  }, [natService]);

  useEffect(() => {
    addTestDataForKey("websocketTests", websocketTests);
  }, [websocketTests]);

  useEffect(() => {
    if (runAdvancedTest === true) {
      setAdvancedTestResult({ result: "Running, please wait..." });
      advancedNetworkTest().then((r) => setAdvancedTestResult(r));
    }
  }, [runAdvancedTest]);

  useEffect(() => {
    if (advancedTestResult)
      addTestDataForKey("advancedNetworkTest", advancedTestResult);
  }, [advancedTestResult]);

  useEffect(() => {
    addTestDataForKey("connectionTypes", connectionState);
  }, [connectionState]);

  useEffect(() => {
    const parms = new URLSearchParams(window.location.search);
    const queryParams = {};
    for (const [key, value] of parms) {
      queryParams[key] = value;
    }
    addTestDataForKey("queryParams", queryParams);
    if (queryParams.staging) setStaging(true);
  }, []);

  async function initiateTester(connectionMode) {
    const instance = new NetworkTester({
      natService,
      connectionMode,
      iceServers,
    });

    instance.setupRTCPeerConnection().then((result) => {
      setConnectionState((prevState) => ({
        ...prevState,
        [connectionMode]: {
          result: result.status,
          iceCandidates: result.iceCandidates,
        },
      }));
    });
  }

  async function runAllTests() {
    setStartedTesting(true);
    const testers = {};
    let reg = staging ? stagingRegions : prodRegions;
    reg.forEach((t) => (testers[t] = { result: "loading" }));
    setWebsocketTests(testers);
    startWebsocketTests(staging).forEach((test) => {
      test.then((rrr) => {
        setWebsocketTests((t) => {
          let newTests = { ...t };
          if (rrr.result.connected === false) setRunAdvancedTest(true);
          newTests[rrr.region] = {
            result: rrr.result.connected ? "passed" : "failed",
            resultMsg: rrr.result.connected
              ? "Connected"
              : rrr.result.message || rrr.result.error,
          };
          return newTests;
        });
      });
    });
    await Promise.all(
      Object.keys(connectionState).map((test) => initiateTester(test))
    );
    setIsTesting(false);
  }

  const Advanced = () => {
    return (
      <div className="detailsContent">
        <p>First, choose your NAT service:</p>
        <div>
          <input
            type="radio"
            id="twilio"
            value="twilio"
            onChange={() => setNatService("twilio")}
            checked={natService === "twilio"}
          />
          <label htmlFor="twilio">Twilio</label>
        </div>
        <div>
          <input
            type="radio"
            id="xirsys"
            value="xirsys"
            onChange={() => setNatService("xirsys")}
            checked={natService === "xirsys"}
          />
          <label htmlFor="xirsys">Xirsys</label>
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <main>
        {!startedTesting && (
          <>
            <Card title="Test your network">
              <p>
                The results of this test will tell you about your network, and
                whether or not you will have issues making video calls on this
                platform. If the results show your network has issues, we'll
                give you tips for how to resolve them.
              </p>
              <p>
                We will test which available connection methods this device
                exposes to other devices. If you want more detailed control over
                which ICE candidates and NAT service we test, check out the
                advanced options below.
              </p>
              <details className="details">
                <summary className="summary">Advanced options</summary>
                <Advanced />
              </details>
              <p>
                {loading ? (
                  "Fetching NAT service, one moment.."
                ) : (
                  <button onClick={runAllTests}>Start test</button>
                )}
              </p>
            </Card>
          </>
        )}
        {startedTesting && (
          <>
            <Results
              natService={natService}
              testerConnectionMode={"all"}
              connectionState={connectionState}
              websocketTests={websocketTests}
              isTesting={isTesting}
              testData={testData}
              addTestDataForKey={addTestDataForKey}
              advancedTestResult={advancedTestResult}
            />
          </>
        )}
      </main>
    </div>
  );
}

export default App;

import React from "react";
import BarLoader from "react-spinners/BarLoader";

const Pass = () => {
  return <span className="pass">Pass</span>;
};

const Fail = () => {
  return <span className="fail">Fail</span>;
};

const Loading = () => {
  return <BarLoader aria-label="Loading Spinner" data-testid="loader" />;
};

/**
 * @param {{pass: boolean, loading: boolean}} props TestStatus props.
 */
export function TestStatus({ pass, loading } = { pass: false, loading: true }) {
  if (loading) return <Loading />;
  return pass ? <Pass /> : <Fail />;
}

/* eslint-disable react-hooks/exhaustive-deps */
import Daily from "@daily-co/daily-js";

import React, { useEffect, useState } from "react";

import { TestStatus } from "./TestStatus";

const GearCheck = ({ testData, addTestDataForKey }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [micEnabled, setMicEnabled] = useState(false);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    addTestDataForKey("mediaDevices", { mic: micEnabled, cam: cameraEnabled });
  }, [micEnabled, cameraEnabled]);
  useEffect(() => {
    async function runChecks() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        stream.getTracks().forEach((track) => {
          if (track.kind === "audio") {
            setMicEnabled(true);
          }

          if (track.kind === "video") {
            setCameraEnabled(true);
          }
          track.stop();
        });
      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    }
    runChecks();
  }, []);

  return (
    <section>
      <h2>Mic and Cam</h2>
      <ul className="servicesList">
        {errorMessage ? (
          <>
            <li>Error</li>
            <li>{errorMessage}</li>
          </>
        ) : null}
        <li>
          <TestStatus loading={isLoading} pass={micEnabled} />
          Microphone Enabled
        </li>
        <li>
          <TestStatus loading={isLoading} pass={cameraEnabled} />
          Camera Enabled
        </li>
      </ul>
    </section>
  );
};

const SupportedBrowser = ({ testData, addTestDataForKey }) => {
  const {
    name,
    supported,
    supportsFullscreen,
    supportsScreenShare,
    supportsSfu,
    supportsVideoProcessing,
    version,
  } = Daily.supportedBrowser();
  const [publicIp, setPublicIp] = useState();
  const [publicIpv6, setPublicIpv6] = useState();
  useEffect(() => {
    addTestDataForKey("publicIp", publicIp);
  }, [publicIp]);
  useEffect(() => {
    addTestDataForKey("publicIpv6", publicIpv6);
  }, [publicIpv6]);
  useEffect(() => {
    const bd = Daily.supportedBrowser();
    console.log("supporting bowser", bd);
    addTestDataForKey("browserData", bd);
  }, []);

  useEffect(() => {
    async function getPublicIP() {
      const ipResp = await fetch("https://api.ipify.org?format=json");
      const ipJson = await ipResp.json();
      setPublicIp(ipJson.ip);
      const ipv6Resp = await fetch("https://api64.ipify.org?format=json");
      const ipv6Json = await ipv6Resp.json();
      setPublicIpv6(ipv6Json.ip);
    }

    getPublicIP().catch(console.error);
  }, []);

  return (
    <section>
      <h2>Browser</h2>

      <ul className="servicesList">
        <li>Daily Version: {Daily.version()}</li>

        <li>
          Browser: {name} {version}
        </li>
        {publicIp && <li>Public IPv4: {publicIp}</li>}
        {publicIpv6 && <li>Public IPv6: {publicIpv6}</li>}
        <li>
          <TestStatus loading={false} pass={supported} />
          Browser Supported?
        </li>
        <li>
          <TestStatus loading={false} pass={supportsFullscreen} />
          Supports Fullscreen
        </li>
        <li>
          <TestStatus loading={false} pass={supportsScreenShare} />
          Supports Screen Share
        </li>
        <li>
          <TestStatus loading={false} pass={supportsSfu} />
          Supports SFU
        </li>
        <li>
          <TestStatus loading={false} pass={supportsVideoProcessing} />
          Supports Video Processing
        </li>
      </ul>
    </section>
  );
};

export default function Check({ testData, addTestDataForKey }) {
  return (
    <section>
      <SupportedBrowser
        testData={testData}
        addTestDataForKey={addTestDataForKey}
      />
      <GearCheck testData={testData} addTestDataForKey={addTestDataForKey} />
    </section>
  );
}

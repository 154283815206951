import "./Card.css";

export function Card({ title, children, isBigCard }) {
  return (
    <section className={`card ${isBigCard ? "bigCard" : ""}`}>
      {title && <h1>{title}</h1>}
      {children}
    </section>
  );
}

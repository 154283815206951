export const CONNECTION_MODES = {
  ALL: "all", // used to gather all candidates
  STUN: "stun",
  TURN_UDP: "turn-udp",
  TURN_TCP: "turn-tcp",
  TURN_TLS: "turn-tls",
  RELAY_ONLY: "relay",
};

export const NAT_SERVICES = {
  TWILIO: "twilio",
  XIRSYS: "xirsys",
};

export const CONNECTION_STATUS = {
  CONNECTED: "connected",
  FAILED: "failed",
  STOPPED: "stopped",
};

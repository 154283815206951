import "./Table.css";

export default function Table({ iceCandidates }) {

  // Firefox doesn't return a lot of info on ice candidates. Let's just list the sdp string in that case.
  const detailedInformationAvailable = iceCandidates.filter((info) => !info.type).length === 0

  return (
    <div className="iceTableContainer">
      <table className="iceTable">
        <thead>
        <tr>
          {detailedInformationAvailable ? (
            <>
              <th scope="col">Type</th>
              <th scope="col">Address</th>
              <th scope="col">Port</th>
              <th scope="col">Protocol</th>
              <th scope="col">Candidate</th>
            </>
          ) : (
            <th scope="col">Candidate</th>
          )}
        </tr>
        </thead>
        <tbody>
        {iceCandidates.map((info, index) => {
          return (
            <tr key={index}>
              {info.type && <td title="Type">{info.type}</td>}
              {info.address && <td title="Address">{info.address}</td>}
              {info.port && <td title="Port">{info.port}</td>}
              {info.protocol && <td title="Protocol">{info.protocol}</td>}
              {info.candidate && <td title="Candidate">{info.candidate}</td>}
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  )
}

import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const useDailyTest = () => {
  const id = uuidv4();
  const startedAt = new Date();
  const [testData, setTestData] = useState({
    id,
    startedAt,
  });

  const addTestDataForKey = (key, data) => {
    setTestData((oldData) => {
      const newTestData = { ...oldData };
      newTestData[key] = data;
      return newTestData;
    });
  };

  return {
    addTestDataForKey,
    testData,
  };
};
